<template>
  <section class="m-user-coupon m-scroll">
    <!-- 头部导航 -->
    <m-header title="钱包明细"></m-header>
    <!-- 优惠券列表 -->
    <van-list  v-model="loading" :finished="finished" finished-text="没有更多记录了，赶紧充值消费吧" @load="onLoad" class="scroll-list">
      <div class="item" v-for="(it,index) in list" :key="index">
        <van-cell center :title="it.detailed_type_title" :value="`$${it.amount}`" :label="it.created_at" />
      </div>
    </van-list>
  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'
import { wallet_record } from '@/api/zh/mine.js'
export default {
  name:'WalletRecord',
  components:{ MHeader },
  data(){
    return {
      list: [],
      page: 0,
      loading: false,
      finished: false,
    }
  },
  methods:{
    getData() {
      let params = { page: this.page }
      wallet_record(params).then(res => {
        if(res) {
          this.list = [...this.list, ...res.data.data]
          this.loading = false
          if(res.data.last_page <= this.page) {
            this.finished = true
          }
        }else {
          this.finished = true
        }
      })
    },
    onLoad() {
      this.page ++ 
      this.getData()
    }
  },
}
</script>

<style lang="less" scoped>
@import './record.less';
</style>